

<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="xl"
      color="secondary"
    >
      <List ref="list" 
        showSearch
        :comboboxData=comboboxData
        :defaultSearchType=searchType
        :fields=fields
        :items.sync=permissionGroupList
        :loading.sync=loading
        sorter      
        :defaultSort=defaultSort
        itemsPerPageSelect
        :itemsPerPage=itemPerPage
        showDetails
        :multiCheck=false
      >    
          <template #no="{index}">
            <td class="py-2" >
              {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
            </td>
          </template>        
          <template #appCnt="{index}">
            <td class="py-2">
              <div v-if="permissionGroupList[index]['allAppPermissionYn'] == '1'">
                 <CBadge color="info">ALL</CBadge>
              </div>
              <div v-else>
                {{permissionGroupList[index]['appCnt']}}
              </div>
            </td>
          </template>
          
        <!--아이템별 상세보기 재정의-->        
        <template slot="show_details">
            <div class="px-md-5">
            <CRow>
                <CCol sm="4" v-for="(items, subIndex) in menuList" :key="subIndex">
                  <CRow form class="form-group" >
                        <CCol tag="label" sm="5" class="col-form-label font-weight-bold">
                            {{items.menuNm}}
                        </CCol>
                    </CRow>
                    <CRow form class="form-group" v-for="(subItem, subIndex) in items.childrenItems" :key="subIndex">
                        <CCol sm="5">
                            {{subItem.menuNm}}
                        </CCol>
                        <CInputCheckbox
                            label="Read"
                            :value="subItem.menuKey"
                            :checked="subItem.readYn==1"
                            :inline="true"
                            :disabled="true"
                            :custom="true"
                            />
                        <CInputCheckbox
                            label="Write"
                            value="write"
                            :checked="subItem.writeYn==1"
                            :disabled="true"
                            :custom="true"
                            />
                        
                    </CRow>
                </CCol>
            </CRow>
            </div>
        </template>      
      </List>
      <page-link ref="page"
      :pageLinkCount=pagePerLink
      :listRowCount=itemPerPage
      />
      <template #header>
        <h6 class="modal-title">권한 그룹 선택</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
      </template>
      <template #footer>
        <CButton color="secondary" @click="$emit('close-modal')">취소</CButton>
        <CButton color="success" @click="addPermissionItem()">선택</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
import List from '@/views/common/List'
import PageLink from '@/views/common/PageLink'
export default {
  name: 'PermissionGroupModal',
  components: {
    List,
    PageLink
  },
  data () {
    return {
        isModalOpen:true,
        searchType:'groupNm',
        searchText:'',
        comboboxData:[{label: '권한 그룹명', value: 'groupNm'}],
        fields:[
            { key: 'check_box', label:'', _style: { width: '1%'}, mapping:'groupKey'},
            { key: 'no', label:'No', _style: { width: '20%'} },
            { key: 'groupNm', label:'권한 그룹명', _style: { width: '20%'} },
            { key: 'appCnt', label:'제어 앱 수', _style: { width: '20%'} },
            { key: 'accountCnt', label:'할당 계정 수',  _style: { width: '20%'} },
            { key: 'regDate', label:'등록일', _style: { width: '20%'} },
        ],
        pagePerLink: 5,
        defaultSort: '-regDate',
        pageNum: 1,
        itemPerPage:5,
        loading:false, 
        checkedList:this.chkGroupKeyList,
        menuList:[],
       
    }
  },
  props: {    
    chkAppKeyList: Array
  },
  computed: {
    permissionGroupList(){
      return this.$store.state.permissionGroup.permissionGroupList || []
    },
    totalCnt(){
      return this.$store.state.permissionGroup.totalCnt || 0
    }
  },
  mounted(){
    this.searchList()
  },
  methods: {
    // //리스트 조회 
    async searchList(){
        let payload = this.$refs.list.getParams()
        payload['adminType'] = '1'
        this.loading=true
        try {
          await this.$store.dispatch('permissionGroup/getPermissionGroupList', payload)
          this.$refs.page.reloadPagination(this.$store.state.permissionGroup.totalCnt)
          this.loading=false
        } catch(error){
          console.log(error)
          this.loading=false
        }
    },
    addPermissionItem(){
       let permissionGroupList = []
       let checkedList = this.$refs.list.getCheckedList()
       if(checkedList.length == 0){
         alert('권한을 선택해주세요.')
         return false
       }
        for(var i in this.permissionGroupList){
          if(checkedList.includes(this.permissionGroupList[i]['groupKey']))  
            permissionGroupList.push(this.permissionGroupList[i])
        }
      this.$emit('add-item-event', permissionGroupList)
    },
    async searchDetail(item, index){  
      try {
        let response = await this.$store.dispatch('permissionGroup/getMenuPermissionInfo', item.groupKey)
        this.menuList = response
        return item
       }catch(error){
         console.log(error)
       }
    },
  }
}
</script>
